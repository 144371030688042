import React from 'react'
import * as PropTypes from 'prop-types'
import Select from 'components/forms/elements/select'
import { Column, Grid } from 'components/grid'

const StepFour = ({ updateValidationSet }) => {
  return (
    <>
      <Grid>
        <Column columns='1/2'>
          <Select
            updateValidationSet={updateValidationSet}
            name='Equipment Type'
            label='Equipment Type'
            required
          >
            <option value=''>Select an option</option>
            <option value='Compact Utility Tractor'>Compact Utility Tractor</option>
            <option value='Gator'>Gator</option>
            <option value='Mower'>Mower</option>
            <option value='Tractor'>Tractor</option>
            <option value='Utility Tractor'>Utility Tractor</option>
            <option value='Other'>Other</option>
          </Select>
        </Column>
        <Column columns='1/2'>
          <Select
            updateValidationSet={updateValidationSet}
            name='Will Organization Transport'
            label='Can organization transport the equipment?'
            required
          >
            <option value=''>Select an option</option>
            <option value='yes'>Yes</option>
            <option value='no'>No</option>
          </Select>
        </Column>
      </Grid>
      <Grid>
        <Column columns='1/2'>
          <Select
            updateValidationSet={updateValidationSet}
            name='Proof of Insurance'
            label='Can Organization provide proof of insurance with Hutson Inc as the loss payee?'
            required
          >
            <option value=''>Select an option</option>
            <option value='yes'>Yes</option>
            <option value='no'>No</option>
          </Select>
        </Column>
        <Column columns='1/2'>
          <Select
            updateValidationSet={updateValidationSet}
            name='Secure Storage'
            label='Can organization provide secure storage?'
            required
          >
            <option value=''>Select an option</option>
            <option value='yes'>Yes</option>
            <option value='no'>No</option>
          </Select>
        </Column>
      </Grid>
    </>
  )
}

StepFour.propTypes = {
  updateValidationSet: PropTypes.func.isRequired,
}

export default StepFour
