import React from 'react'
import * as PropTypes from 'prop-types'
import Input from 'components/forms/elements/input'
import Location from 'components/forms/elements/location'
import State from 'components/forms/elements/state'
import Zip from 'components/forms/elements/zip'
import { Column, Grid } from 'components/grid'

const StepTwo = ({ updateValidationSet }) => {
  return (
    <>
      <Grid>
        <Column columns='1/3'>
          <Input
            updateValidationSet={updateValidationSet}
            label='Organization'
            name='Organization'
            required
          />
        </Column>
        <Column columns='1/3'>
          <Input
            updateValidationSet={updateValidationSet}
            label='Organization Type (Non-Profit, Government, etc.)'
            name='Organization Type'
            required
          />
        </Column>
        <Column columns='1/3'>
          <Input
            updateValidationSet={updateValidationSet}
            label='Organization/Event Website'
            name='Organization/Event Website'
          />
        </Column>
      </Grid>
      <Grid>
        <Column columns='2/3'>
          <Input
            updateValidationSet={updateValidationSet}
            label='Organization Address'
            name='Organization Address'
            required
          />
        </Column>
        <Column columns='1/3'>
          <Input
            updateValidationSet={updateValidationSet}
            label='Organization City'
            name='Organization City'
            required
          />
        </Column>
      </Grid>
      <Grid>
        <Column columns='2/6'>
          <State
            updateValidationSet={updateValidationSet}
            label='Organization State'
            name='Organization State'
            required
          />
        </Column>
        <Column columns='1/6'>
          <Zip
            updateValidationSet={updateValidationSet}
            label='Organization Zip'
            name='Organization Zip'
            required
          />
        </Column>
        <Column columns='3/6'>
          <Location
            label='Nearest Hutson Location'
            updateValidationSet={updateValidationSet}
            required
          ></Location>
        </Column>
      </Grid>
    </>
  )
}

StepTwo.propTypes = {
  updateValidationSet: PropTypes.func.isRequired,
}

export default StepTwo
