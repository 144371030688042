import React from 'react'
import MultiStepForm from 'components/forms/multi-step-form'
import StepOne from './step-one'
import StepTwo from './step-two'
import StepThree from './step-three'
import StepFour from './step-four'

const steps = [
  { name: 'About You', renderComponent: props => <StepOne {...props} /> },
  { name: 'Organization Info', renderComponent: props => <StepTwo {...props} /> },
  { name: 'Event Info', renderComponent: props => <StepThree {...props} /> },
  { name: 'Equipment Details', renderComponent: props => <StepFour {...props} /> },
]

const LoanerForm = () => {
  return <MultiStepForm steps={steps} name='Equipment Loaner' />
}

export default LoanerForm
