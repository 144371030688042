import React from 'react'
import { graphql } from 'gatsby'
import styled from 'styled-components'
import HeroImage from 'components/hero-image'
import Form from 'components/forms/loaner'
import { Helmet } from 'react-helmet'
import Layout from 'components/layout'
import Content from 'components/content'

const LoanerPage = ({ data: { heroImage } }) => {
  return (
    <Layout>
      <Helmet>
        <title>Equipment Loaner Request | Hutson Inc</title>
        <meta
          name='description'
          content="Have a community event coming up where our equipment can be of help? Fill out a loan request form and we'll get back to you soon."
        />
        <script type='application/ld+json'>
          {JSON.stringify({
            '@context': 'http://schema.org',
            '@type': 'BreadcrumbList',
            'itemListElement': [
              {
                '@type': 'ListItem',
                'position': 1,
                'name': 'Hutson Inc',
                'item': 'https://www.hutsoninc.com/',
              },
              {
                '@type': 'ListItem',
                'position': 2,
                'name': 'Equipment Loan Request',
                'item': 'https://www.hutsoninc.com/loaner/',
              },
            ],
          })}
        </script>
      </Helmet>

      <HeroImage title='Equipment Loaner Request Form' image={heroImage} />

      <Content>
        <Message>
          Have a community event where our equipment can be of assistance? Fill out the form below
          and tell us a little bit about it. Please provide as much information as possible.
        </Message>

        <Form />
      </Content>
    </Layout>
  )
}

const Message = styled.p`
  margin-bottom: 40px;
`

export const pageQuery = graphql`
  {
    heroImage: file(relativePath: { eq: "loaner-bg.jpg" }) {
      ...FullWidthImage
    }
  }
`

export default LoanerPage
