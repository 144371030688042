import React from 'react'
import * as PropTypes from 'prop-types'
import Input from 'components/forms/elements/input'
import State from 'components/forms/elements/state'
import Textarea from 'components/forms/elements/textarea'
import { Column, Grid } from 'components/grid'

const StepThree = ({ updateValidationSet }) => {
  return (
    <>
      <Grid>
        <Column columns='1/2'>
          <Input
            updateValidationSet={updateValidationSet}
            label='Event Name'
            name='Event Name'
            required
          />
        </Column>
        <Column columns='1/2'>
          <Input
            updateValidationSet={updateValidationSet}
            label='Event Date'
            name='Event Date'
            type='date'
            required
          />
        </Column>
      </Grid>
      <Grid>
        <Column columns='1/2'>
          <State
            updateValidationSet={updateValidationSet}
            label='Event State'
            name='Event State'
            required
          />
        </Column>
        <Column columns='1/2'>
          <Input
            updateValidationSet={updateValidationSet}
            label='Event City'
            name='Event City'
            required
          />
        </Column>
      </Grid>
      <Textarea
        updateValidationSet={updateValidationSet}
        label='Event Details'
        name='Event Details'
      />
    </>
  )
}

StepThree.propTypes = {
  updateValidationSet: PropTypes.func.isRequired,
}

export default StepThree
